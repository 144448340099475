// extracted by mini-css-extract-plugin
export const main = "FondScroll-module--main--q018D";
export const fondList = "FondScroll-module--fondList--14PPK";
export const listContent = "FondScroll-module--listContent--3tgb-";
export const title = "FondScroll-module--title--3EMpr";
export const fondScroll = "FondScroll-module--fondScroll--3y_MA";
export const listWrapper = "FondScroll-module--listWrapper--2mczx";
export const active = "FondScroll-module--active--2ZtYl";
export const fondTitle = "FondScroll-module--fondTitle--3PusW";
export const arrowContainer = "FondScroll-module--arrowContainer--2EyKW";
export const hidden = "FondScroll-module--hidden--dRZ7u";
export const arrowUp = "FondScroll-module--arrowUp--23mfb";
export const arrowDown = "FondScroll-module--arrowDown--14Hy0";
export const arrow = "FondScroll-module--arrow--tgrWG";