import * as styles from "./FondConfirm.module.scss"

import React, { useRef } from "react"

import { Button } from "dnb-ui-lib/components"
import { PropTypes } from "prop-types"

/**
 * Returns a component used as button to confirm and open a fond
 * @param {toggleOpenFond} func Opens an closes the fond-inspect
 * @param {openFond} bool Specifies if the fond inspect is open
 * @param {disclaimer} string Specifies the disclaimer used in footer
 *
 */

const FondConfirm = ({ toggleOpenFond, openFond, disclaimer }) => {
  const openButtonRef = useRef(null)

  return (
    <div className={styles.confirmContainer}>
      <Button
        ref={openButtonRef}
        variant={"signal"}
        text={"Bekreft"}
        tabIndex={openFond ? -1 : ""}
        onClick={() => {
          toggleOpenFond(true)
        }}
      />
      {disclaimer && (
        <div className={styles.disclaimer}>
          <div className={styles.content}>{disclaimer}</div>
        </div>
      )}
    </div>
  )
}

FondConfirm.propTypes = {
  toggleOpenFond: PropTypes.func.isRequired,
  openFond: PropTypes.bool.isRequired,
  disclaimer: PropTypes.string,
}

FondConfirm.defaultProps = {
  toggleOpenFond: null,
  openFond: false,
  disclaimer: null,
}

export default FondConfirm
