import * as styles from './FondInspect.module.scss';

import { Button, Heading } from 'dnb-ui-lib/components';
import React, { useRef, useLayoutEffect } from 'react';

import PortableText from '@sanity/block-content-to-react';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';

const serializers = {
  marks: {
    link: ({children, mark}) => {
      return (
        <Button
          variant={'signal'}
          text={children}
          href={mark?.href}
        />
      )
    },
  },
};

/**
 * Returns a component used to show more info about a fond
 * @param {items} array List of all the fonds with image, title and desc
 * @param {selectedFond} number Specifies the currently selected fond index
 * @param {disclaimer} string Specifies the disclaimer used in footer
 * @param {openFond} bool Specifies if the fond inspect is open or not
 * @param {toggleOpenFond} func Toggles if the fond inspect is open or not
 *
 */


const FondInspect = ({
  items,
  selectedFond,
  openFond,
  toggleOpenFond,
  disclaimer,
}) => {
  const exitButtonRef = useRef(null);
  const fondData = items[selectedFond];
  const scrollRef = useRef(null);

  useLayoutEffect(() => {
    const scrollElement = scrollRef.current;
    if (openFond === true) {
      scrollElement.scrollTo(0, 0);
    }
  }, [openFond]);

  console.log(fondData);

  return (
    <div
      className={classNames(styles.fondInspect, { [styles.open]: openFond })}
    >
      <div className={styles.wrapper}>
        <div className={styles.text} ref={scrollRef}>
          <div className={styles.content}>
            <svg
              className={styles.icon}
              width="156"
              height="141"
              viewBox="0 0 156 141"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0)">
                <path
                  d="M106.094 44.7903C106.453 44.7903 106.812 44.7903 107.351 44.7903C108.428 44.7903 109.326 44.7903 110.403 44.7903C151.691 44.7903 156 4.47901 156 4.12068C156.179 3.04572 155.641 1.97075 154.923 1.2541C154.205 0.537456 153.128 -2.80738e-05 152.051 0.179133C128.175 1.07494 114.352 17.0203 107.889 28.845C98.1955 5.37481 71.4475 3.22488 62.6512 3.22488C60.6765 3.22488 59.4199 3.40404 59.2404 3.40404C58.1633 3.5832 57.0862 4.12068 56.5477 5.01649C55.8296 5.9123 55.6501 6.98727 56.0091 8.06223C63.3693 38.3405 99.2726 44.7903 106.094 44.7903ZM147.742 7.88307C145.588 16.8411 138.048 36.3697 112.198 37.2655C115.429 29.3824 126.021 11.108 147.742 7.88307ZM102.863 36.9072C93.7076 35.6531 71.9861 29.3824 64.6259 10.7497C91.7329 11.4663 100.17 27.0533 102.863 36.9072Z"
                  fill="#28B482"
                />
                <path
                  d="M140.921 133.475H133.56C131.765 128.996 127.457 116.276 123.687 88.6849C119.558 59.3024 119.738 53.2109 119.738 52.136C119.917 51.4193 119.917 50.5235 119.558 49.8069C119.02 48.3736 117.583 47.4778 116.147 47.4778H99.6318C97.6571 47.4778 95.8619 49.0902 95.8619 51.061C95.8619 51.061 95.6824 55.8984 94.9643 68.4397C94.4258 80.0852 92.9896 90.2973 91.5535 98.7179C91.374 88.3266 91.1945 77.7561 91.1945 72.9187C91.1945 70.7688 89.5788 69.1563 87.4246 69.1563H81.5006V55.8984C81.5006 54.4651 80.603 53.0318 79.3464 52.4943C78.0898 51.9568 76.4741 52.136 75.397 53.0318L61.7537 64.6773V55.8984C61.7537 54.4651 60.8562 53.0318 59.5995 52.4943C58.3429 51.9568 56.7273 52.136 55.6502 53.0318L42.0069 64.6773V55.8984C42.0069 54.4651 41.1093 53.0318 39.8527 52.4943C38.5961 51.9568 36.9804 52.136 35.9033 53.0318L15.618 70.2313C14.7204 70.9479 14.3613 72.0229 14.3613 73.0979V133.475H3.76985C1.61565 133.475 0 135.088 0 137.238C0 139.388 1.61565 141 3.76985 141H18.1312H131.227H140.921C143.075 141 144.69 139.388 144.69 137.238C144.69 135.088 143.075 133.475 140.921 133.475ZM21.901 133.475V74.8895L34.2877 64.319V73.0979C34.2877 74.5311 35.1853 75.9644 36.4419 76.5019C37.6985 77.0394 39.3142 76.8603 40.3913 75.9644L54.0345 64.319V73.0979C54.0345 74.5311 54.9321 75.9644 56.1887 76.5019C57.4453 77.0394 59.061 76.8603 60.1381 75.9644L73.7814 64.319V73.0979C73.7814 75.2478 75.397 76.8603 77.5512 76.8603H83.4753C83.6548 91.3723 84.3728 125.771 84.3728 126.13C84.3728 127.921 85.6295 129.355 87.4246 129.713C89.2198 130.071 91.015 128.996 91.5535 127.384C91.9125 126.488 100.709 104.272 102.325 68.9771C102.684 62.3482 103.043 57.8691 103.043 55.1817H112.198C112.377 60.3774 113.455 70.4104 116.147 89.939C119.379 113.588 123.148 127.025 125.482 133.654H21.901V133.475Z"
                  fill="#28B482"
                />
                <path
                  d="M36.8008 85.9974H27.1069V95.6722H36.8008V85.9974Z"
                  fill="#28B482"
                />
                <path
                  d="M36.8008 107.139H27.1069V116.813H36.8008V107.139Z"
                  fill="#28B482"
                />
                <path
                  d="M56.7276 85.9974H47.0337V95.6722H56.7276V85.9974Z"
                  fill="#28B482"
                />
                <path
                  d="M56.7276 107.139H47.0337V116.813H56.7276V107.139Z"
                  fill="#28B482"
                />
                <path
                  d="M76.6534 85.9974H66.9595V95.6722H76.6534V85.9974Z"
                  fill="#28B482"
                />
                <path
                  d="M76.6534 107.139H66.9595V116.813H76.6534V107.139Z"
                  fill="#28B482"
                />
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect width="156" height="141" fill="white" />
                </clipPath>
              </defs>
            </svg>

            <div className={styles.description}>
              {fondData.fondName && (
                <Heading className={styles.name}>{fondData.fondName}</Heading>
              )}
              {fondData._rawDescription && (
                <PortableText
                  serializers={serializers}
                  blocks={fondData._rawDescription}
                />
              )}
            </div>
            {disclaimer && (
              <div className={styles.disclaimer}>
                <div className={styles.content}>{disclaimer}</div>
              </div>
            )}
          </div>
          <div className={styles.buttonContainer}>
            <Button
              ref={exitButtonRef}
              variant={'tertiary'}
              icon="chevron_left"
              icon_position="left"
              text={'Tilbake til valg'}
              tabIndex={!openFond ? '-1' : '0'}
              onClick={() => {
                toggleOpenFond(false);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

FondInspect.propTypes = {
  selectedFond: PropTypes.number.isRequired,
  openFond: PropTypes.bool.isRequired,
  toggleOpenFond: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
};

FondInspect.defaultProps = {
  selectedFond: null,
  openFond: null,
  toggleOpenFond: null,
  items: null,
};

export default FondInspect;
