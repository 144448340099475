// extracted by mini-css-extract-plugin
export const fondInspect = "FondInspect-module--fondInspect--4Lubi";
export const open = "FondInspect-module--open--1wFqF";
export const wrapper = "FondInspect-module--wrapper--bbQ3S";
export const text = "FondInspect-module--text--2Fbzn";
export const content = "FondInspect-module--content--3z3HI";
export const name = "FondInspect-module--name--3LKIr";
export const icon = "FondInspect-module--icon--zqi-4";
export const description = "FondInspect-module--description--1PiJK";
export const disclaimer = "FondInspect-module--disclaimer--3AeJe";
export const buttonContainer = "FondInspect-module--buttonContainer--3AURf";