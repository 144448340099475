import React from "react"
import * as styles from "./Filter.module.scss"
import { PropTypes } from "prop-types"

/**
 * Returns a div with opacity to easily darken images and backgrounds
 * @param {strength} number Specifies the opacity of the filter
 */

const Filter = ({ strength }) => {
  return <div className={styles.filter} style={{ opacity: strength }} />
}

Filter.propTypes = {
  strength: PropTypes.number,
}

Filter.defaultProps = {
  strength: 0.5,
}

export default Filter
