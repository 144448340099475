import * as styles from "./FondScroll.module.scss"

import React, { useEffect, useLayoutEffect, useRef, useState } from "react"

import FondConfirm from "./fond-confirm/FondConfirm"
import FondInspect from "./fond-inspect/FondInspect"
import { Heading, Icon } from "dnb-ui-lib/components"
import { PropTypes } from "prop-types"
import ScrollMedia from "./scroll-media/ScrollMedia"
import classNames from "classnames"
import {
  chevron_up as ArrowUp,
  chevron_down as ArrowDown,
} from "dnb-ui-lib/icons"

/**
 * Returns a component used as hero styled fond scroll and inspector
 * @param {title} string Specifies the title used in section
 * @param {disclaimer} string Specifies the disclaimer used in footer
 * @param {items} array List of all the fonds with image, title and desc
 *
 */

const useResize = listRef => {
  const [itemHeight, setItemHeight] = useState(58)
  useLayoutEffect(() => {
    function updateSize() {
      let largestItem = 0
      const listItem = listRef.current
      if (listItem) {
        for (var i = 0; i < listItem.children.length; i++) {
          const singleItem = listItem.children[i]
          const currentItemHeight = singleItem.clientHeight
          if (currentItemHeight > largestItem) {
            largestItem = currentItemHeight
          }
        }
        setItemHeight(largestItem)
      }
    }
    window.addEventListener("resize", updateSize)
    updateSize()
    return () => window.removeEventListener("resize", updateSize)
  }, [])
  return itemHeight
}

const FondScroll = ({ title, disclaimer, items }) => {
  const scrollRef = useRef(null)
  const listRef = useRef(null)

  const [openFond, toggleOpenFond] = useState(false)
  const [selectedFond, setSelectedFond] = useState(0)
  const lineHeight = useResize(listRef)

  const [activeSwipe, setActiveSwipe] = useState(false)
  const [activeScroll, setActiveScroll] = useState(false)
  const [denyButtons, setDenyButtons] = useState(false)
  const [scrollPosition, setScrollPosition] = useState(0)
  const [startScrollPosition, setStartScrollPosition] = useState(0)
  const [currentScrollPosition, setCurrentScrollPosition] = useState(0)

  const settings = {
    visibleSlides: 3, // The number of slides that will be visible at once
    sacleOffset: 1.4, // Adds extra scale to elements that are upcomming
    opaciyOffset: 1.2, // Adds extra opacity to elements that are upcomming
    overflowOffest: 0.25, // Movement when scrolling past last item in list
  }

  const forceFond = index => {
    if (index >= 0 && index < items.length) {
      setScrollPosition(lineHeight * index)
    }
  }

  const startSwipe = yPos => {
    setStartScrollPosition(yPos)
    setActiveSwipe(true)
  }

  const doSwipe = yPos => {
    const change = startScrollPosition - yPos
    setCurrentScrollPosition(change)
  }

  const endSwipe = () => {
    const minPos = 0
    const maxPos = lineHeight * (items.length - 1)
    const totalPosition = scrollPosition + currentScrollPosition
    let endPosition = Math.round(totalPosition / lineHeight) * lineHeight
    endPosition = Math.min(Math.max(endPosition, minPos), maxPos)
    setScrollPosition(endPosition)
    setCurrentScrollPosition(0)
    setActiveSwipe(false)
  }

  const useKeyPress = () => {
    const keyDown = event => {
      // Arrow up
      if (openFond === false) {
        if (event.keyCode === 38) {
          forceFond(selectedFond - 1)
        } // Arrow down
        else if (event.keyCode === 40) {
          forceFond(selectedFond + 1)
        } // Enter
        else if (event.keyCode === 13) {
          toggleOpenFond(true)
        }
      } else {
        // Escape
        if (event.keyCode === 27) {
          toggleOpenFond(false)
        }
      }
    }
    useEffect(() => {
      document.addEventListener("keydown", keyDown)
      return () => {
        document.removeEventListener("keydown", keyDown)
      }
    }, [selectedFond, openFond])
  }
  useKeyPress()

  useLayoutEffect(() => {
    const list = listRef.current
    list.addEventListener(
      "touchmove",
      event => {
        event.preventDefault()
      },
      false
    )
    return () => {
      list.removeEventListener(
        "touchmove",
        event => {
          event.preventDefault()
        },
        false
      )
    }
  }, [])

  useLayoutEffect(() => {
    const updateScroller = () => {
      const list = listRef.current
      let activePos = scrollPosition + currentScrollPosition

      // Throttle on scroll overscroll
      const maxScroll = lineHeight * (items.length - 1)
      if (activePos < 0) {
        activePos *= settings.overflowOffest
      } else if (activePos > maxScroll) {
        activePos =
          maxScroll + (activePos - maxScroll) * settings.overflowOffest
      }

      // Find nearest child based on height
      const nearest = Math.min(
        Math.max(Math.round(activePos / lineHeight), 0),
        items.length - 1
      )

      // Set fond to closes child
      if (selectedFond !== nearest) {
        setSelectedFond(nearest)
      }

      // Scale and opacity all children
      for (var i = 0; i < items.length; i++) {
        const item = list.children[i]
        const itemPosition = i * lineHeight

        const offsetPx = Math.abs(itemPosition - activePos)
        let offsetPercentage =
          1 - offsetPx / lineHeight / (settings.visibleSlides - 1)
        offsetPercentage *= settings.sacleOffset
        offsetPercentage = Math.min(Math.max(offsetPercentage, 0), 1)

        item.style.transform = `scale(${offsetPercentage})`
        if (offsetPercentage < 0.5) {
          item.style.opacity = 0
        } else {
          item.style.opacity = offsetPercentage * settings.opaciyOffset
        }

        if (nearest === i) {
          item.classList.add(styles.active)
        } else {
          item.classList.remove(styles.active)
        }
      }

      // Transform wrapper
      list.style.transform = `translateY(calc( ${activePos * -1}px))`
    }
    window.requestAnimationFrame(updateScroller)
  }, [
    scrollPosition,
    currentScrollPosition,
    lineHeight,
    items.length,
    settings.opaciyOffset,
    settings.overflowOffest,
    settings.sacleOffset,
    settings.visibleSlides,
  ])

  // Set all depending heights acording to current element height
  useLayoutEffect(() => {
    const scrollElement = scrollRef.current
    const listElement = listRef.current
    scrollElement.style.height = `${lineHeight * settings.visibleSlides}px`
    listElement.style.top = `calc(50% - ${lineHeight / 2}px)`
  }, [lineHeight])

  const fondList = items.map(({ _key, fondTitle }, index) => {
    return (
      <button
        key={_key}
        className={styles.fondTitle}
        tabIndex={-1}
        onClick={() => {
          if (!activeSwipe && !denyButtons) {
            if (index === selectedFond) {
              toggleOpenFond(true)
            } else if (!openFond) {
              forceFond(index)
            }
          }
        }}
      >
        {fondTitle}
      </button>
    )
  })

  return (
    <section
      className={classNames(styles.main, { [styles.active]: activeSwipe })}
      onMouseMove={event => {
        if (activeSwipe) {
          setDenyButtons(true)
          doSwipe(event.screenY)
        }
      }}
      onMouseUp={() => {
        if (activeSwipe) {
          endSwipe()
          setTimeout(function () {
            setDenyButtons(false)
          }, 50)
        }
      }}
      onMouseLeave={() => {
        if (activeSwipe) {
          endSwipe()
          setDenyButtons(false)
        }
      }}
      onWheel={event => {
        if (!activeScroll && !openFond) {
          setActiveScroll(true)
          const scrollChange = event.nativeEvent.deltaY
          if (scrollChange < 0) {
            forceFond(selectedFond - 1)
          } else {
            forceFond(selectedFond + 1)
          }
          let throttle = 250
          // Increase throttle on trackpad
          if (Math.abs(scrollChange) < 10) {
            throttle = 500
          }
          setTimeout(function () {
            setActiveScroll(false)
          }, throttle)
        }
      }}
    >
      <ScrollMedia items={items} selectedFond={selectedFond} />
      <div className={styles.fondList}>
        <div className={styles.listContent}>
          <Heading className={styles.title}>{title}</Heading>
          <button
            className={classNames(styles.arrowContainer, styles.arrowUp, {
              [styles.hidden]: selectedFond === 0,
            })}
            onClick={() => {
              forceFond(selectedFond - 1)
            }}
          >
            <Icon
              icon={ArrowUp}
              size={"medium"}
              className={styles.arrow}
              aria-hidden
            />
          </button>
          <div
            ref={scrollRef}
            className={styles.fondScroll}
            onTouchStart={event => {
              startSwipe(event.changedTouches[0].clientY)
            }}
            onTouchMove={event => {
              doSwipe(event.changedTouches[0].clientY)
            }}
            onTouchEnd={() => {
              endSwipe()
            }}
            onMouseDown={event => {
              startSwipe(event.screenY)
            }}
          >
            <div
              ref={listRef}
              className={classNames(styles.listWrapper, {
                [styles.active]: activeSwipe,
              })}
            >
              {fondList}
            </div>
          </div>
          <button
            className={classNames(styles.arrowContainer, styles.arrowDown, {
              [styles.hidden]: selectedFond === items.length - 1,
            })}
            onClick={() => {
              forceFond(selectedFond + 1)
            }}
          >
            <Icon
              icon={ArrowDown}
              size={"medium"}
              className={styles.arrow}
              aria-hidden
            />
          </button>
        </div>
      </div>
      <FondConfirm
        toggleOpenFond={toggleOpenFond}
        openFond={openFond}
        disclaimer={disclaimer}
      />
      <FondInspect
        items={items}
        selectedFond={selectedFond}
        openFond={openFond}
        toggleOpenFond={toggleOpenFond}
        disclaimer={disclaimer}
      />
    </section>
  )
}

FondScroll.propTypes = {
  title: PropTypes.string.isRequired,
  disclaimer: PropTypes.string,
  items: PropTypes.array.isRequired,
}

FondScroll.defaultProps = {
  title: null,
  disclaimer: null,
  items: null,
}

export default FondScroll
