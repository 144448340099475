import FondScroll from "../components/fond-scroll/FondScroll"
import Layout from "../components/layout/Layout"
import React from "react"
import SEO from "../components/seo/Seo"
import { graphql } from "gatsby"

const Fondsveilder = ({ data }) => {
  const fondData = data.allSanityPageFond.edges[0].node
  const seoData = fondData.seoData
  return (
    <Layout overlay={true}>
      <SEO
        title={seoData && seoData.seoTitle ? seoData.seoTitle : "Miljøfond"}
        description={
          seoData && seoData.seoDescription ? seoData.seoDescription : null
        }
        image={
          seoData && seoData.image ? seoData.seoImage.asset.fixed.src : null
        }
      />
      <FondScroll
        items={fondData.fondList}
        title={fondData.description}
        disclaimer={fondData.disclaimer}
      />
    </Layout>
  )
}

export const query = graphql`
  {
    allSanityPageFond {
      edges {
        node {
          title
          description
          disclaimer
          fondList {
            _key
            fondTitle
            fondName
            _rawDescription(resolveReferences: { maxDepth: 10 })
            description {
              ...BlockContent
            }
            backgroundMedia {
              video {
                _key
                asset {
                  thumbTime
                  status
                  playbackId
                  filename
                  assetId
                }
              }
              image {
                _key
                alt
                asset {
                  fluid(maxWidth: 2000) {
                    ...GatsbySanityImageFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Fondsveilder
