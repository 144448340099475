import * as styles from "./ScrollMedia.module.scss"

import React, { useLayoutEffect, useRef } from "react"

import Filter from "../../filter/Filter"
import Image from "gatsby-image"
import { PropTypes } from "prop-types"
import VideoPlayer from "../../video-player/VideoPlayer"
import classNames from "classnames"

/**
 * Returns a component used as a background for FondScroll
 * @param {selectedFond} number Specifies the current image
 * @param {items} array List of all the fonds with image, title and desc
 *
 */

const ScrollMedia = ({ selectedFond, items }) => {
  const mediaRef = useRef(null)

  useLayoutEffect(() => {
    const allMedia = mediaRef.current.children
    for (let i = 0; i < allMedia.length; i++) {
      const mediaDom = allMedia[i]
      if (i > selectedFond) {
        mediaDom.style.opacity = 0
      } else {
        mediaDom.style.opacity = 1
      }
    }
  }, [selectedFond])

  const media = items.map(({ _key, backgroundMedia }) => {
    if (backgroundMedia.video) {
      return (
        <VideoPlayer
          key={_key}
          className={classNames(styles.media, styles.video)}
          asset={backgroundMedia.video.asset}
          isBackgroundVideo={true}
        />
      )
    }
    return (
      <div key={_key} className={classNames(styles.media, styles.image)}>
        <Image
          fluid={backgroundMedia.image.asset.fluid}
          objectFit={"cover"}
          alt={`${backgroundMedia.image.alt}`}
        />
      </div>
    )
  })

  return (
    <div className={styles.fondMedia}>
      <div ref={mediaRef}>{media}</div>
      <Filter strength={0.3} />
    </div>
  )
}

ScrollMedia.propTypes = {
  selectedFond: PropTypes.number.isRequired,
  items: PropTypes.array.isRequired,
}

ScrollMedia.defaultProps = {
  selectedFond: null,
  items: null,
}

export default ScrollMedia
